

export enum ELECTION_STATUS {
    DRAFT = "DRAFT",
    READY = "READY",
    NOMINATIONS = "NOMINATIONS",
    RUNNING = "RUNNING",
    COMPLETE = "COMPLETE",
    CANCELLED = "CANCELLED"
}

export enum ELECTION_MODE {
    SCHEDULE = "SCHEDULE",
    MANUAL = "MANUAL",
}


export interface IElection {
    id: string;
    created_at: Date | string;
    organisation_id: string;
    label: string;
    status: ELECTION_STATUS;
    short_description?: string;
    mode: ELECTION_MODE;
    nominations_close_at?: Date | string;
    nominations_open_at?: Date | string;
    voting_close_at: Date | string;
    voting_open_at: Date | string;
}