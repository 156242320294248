import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { ElectionContext, SubmitResult } from "./context";
import { ErrorPanel } from "../../components/error-panel";
import { BallotResponse, IBallot } from "../../domain/ballot";
import { RESPONSE_TYPE } from "../../const/vote";
import { sortResult } from "./util";
import { Loading } from "../../components/loading";

export const ReviewPage = () => {
  const { response, ballots, submit } = useContext(ElectionContext);
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState<SubmitResult | null>(null);
  const done = useMemo(() => !!result && result.success, [result]);
  if (!ballots) {
    return <ErrorPanel text="Must have ballots" source="ReviewPage" />;
  }

  const handleSubmit = () => {
    setSubmitting(true);
    setTimeout(() => submit().then(setResult), 1000);
  };

  if (submitting || done) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Thank you for participating in this election.</Typography>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Loading source="Review" waitingFor={{ Submit: done }} />
          <Typography
            color="green"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            Submitted!
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4">Review</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Please review your submission before submitting. After submission you
          will not be able to change your vote.
        </Typography>
      </Grid>

      <Grid item xs={12} mb={3}>
        {ballots.map((b) => (
          <BallotReview key={b.id} ballot={b} response={response[b.id]} />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
      {result !== null && result.success === false ? (
        <Grid item xs={12} mt={4}>
          <Typography color="error">Error</Typography>
          <Typography color="error">{result.message}</Typography>
          <Typography color="error">{result.code}</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

interface BallotReviewProps {
  ballot: IBallot;
  response: BallotResponse;
}
const BallotReview = ({ ballot, response }: BallotReviewProps) => {
  return (
    <Card sx={{ border: "#AAA solid 1px" }}>
      <Box p={3}>
        <Typography>{ballot.label}</Typography>
        <hr />
        {response ? (
          <ResponseReview response={response} />
        ) : (
          <Typography color="error">ERROR EMPTY RESPONSE</Typography>
        )}
      </Box>
    </Card>
  );
};
interface ResponseReviewProps {
  response: BallotResponse;
}
const ResponseReview = ({ response }: ResponseReviewProps) => {
  switch (response.type) {
    case RESPONSE_TYPE.RANKING:
    case RESPONSE_TYPE.PREFERENCE:
      response.value.sort(sortResult);
      return (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={80}>
                Preference
              </TableCell>
              <TableCell>Candidate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {response.value.map((r, i) => (
              <TableRow key={i}>
                <TableCell align="center">{r.index + 1}</TableCell>
                <TableCell>
                  <CandidateName candidate_id={r.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
  }
};

interface CandidateNameProps {
  candidate_id: string;
}
const CandidateName = ({ candidate_id }: CandidateNameProps) => {
  const { candidates } = useContext(ElectionContext);
  const candidate = useMemo(
    () =>
      candidates ? candidates.find((c) => c.id === candidate_id) : undefined,
    [candidates, candidate_id]
  );
  if (!candidate) {
    return <Typography>{candidate_id}</Typography>;
  }
  if (candidate.preferred_name && candidate.preferred_name != "") {
    return <Typography>{candidate.preferred_name}</Typography>;
  }
  return (
    <Typography>
      {candidate.first_name} {candidate.last_name}
    </Typography>
  );
};
