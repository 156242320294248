import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
  useCallback,
} from "react";
import { IElection } from "../domain/election";
import { AUTH_STATUS, AuthContext } from "./auth.provider";

export interface IElectionsContext {
  elections: IElection[] | null;
  fetchElections: () => void;
  getElectionById: (id: string) => IElection | null;
}

export const ElectionsContext = createContext<IElectionsContext>({
  elections: null,
  fetchElections: () => null,
  getElectionById: () => null,
});

export const ElectionsProvider: React.FC<PropsWithChildren> = (props) => {
  const [elections, setElections] = useState<Array<IElection> | null>(null);

  const { fetch, status } = useContext(AuthContext);
  const fetchElections = useCallback(() => {
    if (status === AUTH_STATUS.AUTHENTICATED && fetch) {
      fetch({
        method: "get",
        url: "/elections",
      }).then(setElections);
    }
  }, [fetch, status]);

  useEffect(() => {
    fetchElections();
    const timer = setInterval(fetchElections, 60000);
    clearInterval(timer);
  }, [fetchElections, status]);

  const getElectionById = (id: string) => {
    if (!elections) {
      return null;
    }
    return elections.find((e) => e.id == id) || null;
  };

  return (
    <ElectionsContext.Provider
      value={{ elections, fetchElections, getElectionById }}
    >
      {props.children}
    </ElectionsContext.Provider>
  );
};
