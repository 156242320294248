import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { IVoter } from "../domain/voter";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwt from "jwt-encode";
import { API_BASE } from "../const/config";
import { LocalStorageContext } from "./localstorage.provider";
import { IEmailToken } from "../domain/email-token";
export enum AUTH_STATUS {
  VERIFYING = "VERIFYING",
  AUTHENTICATED = "AUTHENTICATED",
  UNAUTHENTICATED = "UNAUTHENTICATED",
}
export interface IAuthContext {
  authByEmailToken: (code: string) => Promise<IEmailToken>;
  fetch: null | (<T = any>(config: AxiosRequestConfig<T>) => Promise<T>);
  voter: IVoter | null;
  status: AUTH_STATUS;
}

export interface HttpRequest {}

export const AuthContext = createContext<IAuthContext>({
  authByEmailToken: (code: string) => {
    throw new Error("Not initialized");
  },
  fetch: null,
  voter: null,
  status: AUTH_STATUS.VERIFYING,
});
export const AuthProvider: React.FC<PropsWithChildren> = (props) => {
  const { getString, setString, delValue } = useContext(LocalStorageContext);
  const publicKey = getString("public_key");
  const secretKey = getString("secret_key");
  const [status, setStatus] = useState<AUTH_STATUS>(AUTH_STATUS.VERIFYING);

  const [voter, setUser] = useState<IVoter | null>(null);

  const deAuth = () => {
    setStatus(AUTH_STATUS.UNAUTHENTICATED);
    delValue("public_key");
    delValue("secret_key");
  };

  const authByEmailToken = (email_token_id: string) => {
    console.log("INITIALIZED");
    return axios({
      url: API_BASE + "/email_token",
      method: "post",
      data: { email_token_id },
    }).then(({ data }) => {
      console.log({ data });
      const { api_token, email_token } = data;
      setString("public_key", api_token.public_key);
      setString("secret_key", api_token.secret_key);
      return email_token;
    });
  };

  const fetch = useMemo(() => {
    if (!publicKey || !secretKey) {
      return null;
    }
    return (config: AxiosRequestConfig<any>) => {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Accept"] = "application/json";
      config.url = API_BASE + "/auth" + config.url;

      if (publicKey && secretKey) {
        var token = jwt({ public_key: publicKey }, secretKey);
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return axios(config).then((response) => response.data);
    };
  }, [publicKey, secretKey]);

  useEffect(() => {
    if (fetch) {
      fetch({
        method: "get",
        url: "/status",
      })
        .then((user) => {
          setUser(user);
          setStatus(AUTH_STATUS.AUTHENTICATED);
        })
        .catch(() => deAuth());
    } else {
      setStatus(AUTH_STATUS.UNAUTHENTICATED);
    }
  }, [fetch, publicKey, secretKey]);

  return (
    <AuthContext.Provider value={{ authByEmailToken, fetch, voter, status }}>
      {props.children}
    </AuthContext.Provider>
  );
};
