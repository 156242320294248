import {
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
  Children,
  useCallback,
  createContext,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Loading } from "../loading";
import { useStateWithRef } from "../../hooks";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import {
  DragElem,
  DropCache,
  IdType,
  OffsetCache,
  Position,
  PositionCache,
  SortResult,
} from "./types";

export interface Elem {
  id: string;
  label?: string;
}

export interface IRankSortContext {
  positions: PositionCache;
  offsets: OffsetCache;
  lineHeight: number;
  grabId: IdType | null;
  ranked: Array<Elem>;
  unranked: Array<Elem>;
  items: Array<Elem>;
  maxRanking?: number;
  minRanking?: number;
  onBounds: (position: Position, rect: DOMRect) => void;
  onDrop: (position: Position, id: IdType) => void;
  onOver: (position: Position, isOver: boolean) => void;
  onDrag: (position: Position, id: IdType, dragging: boolean) => void;
  dropLocations: DropCache;
}

export const RankSortContext = createContext<IRankSortContext>({
  positions: {},
  offsets: {},
  lineHeight: 0,
  grabId: null,
  ranked: [],
  unranked: [],
  maxRanking: 0,
  items: [],
  dropLocations: [],
  onBounds: (position: Position, rect: DOMRect) => undefined,
  onDrop: (position: Position, id: IdType) => undefined,
  onOver: (position: Position, isOver: boolean) => undefined,
  onDrag: (position: Position, id: IdType) => undefined,
});

const getId = (element: any, fallback: IdType = ""): IdType =>
  "props" in element ? element.props.id : fallback;

interface RankSortContainerProps extends PropsWithChildren {
  initial?: Array<Elem>;
  items: Array<Elem>;
  maxRanking?: number;
  minRanking?: number;
  onChange?: (val: Array<SortResult>, valid: boolean) => void;
}
export const RankSortProvider = ({
  items,
  initial,
  children,
  maxRanking,
  minRanking,
  onChange,
}: RankSortContainerProps) => {
  let [ranked, setRanked, rankedRef] = useStateWithRef<Array<Elem>>([]);
  let [unranked, setUnranked, unrankedRef] = useStateWithRef<Array<Elem>>([]);
  let [dropLocations, setDropLocations, dropLocationsRef] =
    useStateWithRef<DropCache>({});

  const [overPosition, setOverPosition] = useState<Position | null>(null);
  const [grabId, setGrabId] = useState<IdType | null>(null);

  const [dropZoneTop, setDropZoneTop] = useState<number>(0);

  useEffect(() => {
    const sortResult = ranked.map(
      (o, i): SortResult => ({ id: o.id, index: i, label: o.label })
    );
    const valid =
      (!minRanking || sortResult.length >= minRanking) &&
      (!maxRanking || sortResult.length <= maxRanking);
    if (onChange && sortResult) onChange(sortResult, valid);
  }, [ranked]);

  useEffect(() => {
    const init = initial
      ? initial.map((e) => (e.label ? e : items.find((i) => i.id === e.id)))
      : [];

    setRanked(init.filter((i) => i) as Array<Elem>);
    setUnranked(
      initial
        ? items.filter((i) => !initial.find((j) => j.id === i.id))
        : items.slice()
    );
  }, [items]);

  const calculateDrop = useCallback(
    (id: IdType, [dropList, dropIndex]: Position) => {
      let _tempRanked = rankedRef.current || [];
      let _tempunranked = unrankedRef.current || [];
      _tempRanked = _tempRanked.filter((r) => r.id !== id);
      _tempunranked = _tempunranked.filter((r) => r.id !== id);
      const item = items.find((i) => i.id === id);
      if (!item) {
        return [_tempRanked, _tempunranked];
      }

      if (dropList === 0) {
        _tempRanked.splice(dropIndex, 0, item);
        while (maxRanking && _tempRanked.length > maxRanking) {
          const t = _tempRanked.pop();
          if (t) _tempunranked.unshift(t);
        }
      }
      if (dropList === 1) {
        _tempunranked.splice(dropIndex, 0, item);
      }
      return [_tempRanked, _tempunranked];
    },
    [ranked, unranked]
  );
  // use a temp list for displaying the dragover effects
  const [_ranked, _unranked] = useMemo(() => {
    if (!overPosition || !grabId) {
      return [ranked, unranked];
    }
    return calculateDrop(grabId, overPosition);
  }, [overPosition, grabId, calculateDrop]);

  const handleDrop = useCallback(
    (position: Position, id: IdType) => {
      const [_ranked, _unranked] = calculateDrop(id, position);
      setUnranked(_unranked);
      setRanked(_ranked);
    },
    [ranked, calculateDrop]
  );

  const handleOver = (address: Position, isOver: boolean) => {
    if (isOver) {
      setOverPosition(address);
    }
  };
  const handleDrag = (position: Position, id: IdType, isDragging: boolean) => {
    if (isDragging) {
      setGrabId(id);
    } else {
      setGrabId(null);
      setOverPosition(null);
    }
  };

  const lineHeight = useMemo(() => {
    for (const _list of Object.keys(dropLocations)) {
      const list = Number(_list);
      for (const _index of Object.keys(dropLocations[list])) {
        const index = Number(_index);
        const next = index + 1;
        if (next in dropLocations[list]) {
          return dropLocations[list][next] - dropLocations[list][index];
        }
      }
    }
    return 0;
  }, [dropLocations]);

  const getPosition = (id: IdType): Position => {
    if (!_ranked || !_unranked) {
      return [0, 0];
    }
    const r = _ranked.findIndex((r) => r.id === id);
    if (r !== -1) return [0, r];
    const u = _unranked.findIndex((r) => r.id === id);
    if (u !== -1) return [1, u];

    return [-1, -1];
  };
  const positions: PositionCache = useMemo(
    () =>
      items.reduce(
        (prev: PositionCache, item: Elem) => ({
          ...prev,
          [item.id]: getPosition(item.id),
        }),
        {}
      ),
    [_ranked, _unranked]
  );

  const offsets: OffsetCache = useMemo(
    () =>
      items.reduce((prev: OffsetCache, item: Elem): OffsetCache => {
        const [list, index] = positions[item.id];

        if (
          dropLocations &&
          list in dropLocations &&
          index in dropLocations[list]
        ) {
          return {
            ...prev,
            [item.id]: dropLocations[list][index] - dropZoneTop,
          };
        }

        return {
          ...prev,
          [item.id]: 0,
        };
      }, {}),
    [positions, dropLocations, dropZoneTop]
  );

  if (!items || !ranked || !unranked) {
    return (
      <Loading
        source="RankSortContainer"
        waitingFor={{
          Items: !!items,
          Ranked: !!ranked,
          Unranked: !!unranked,
        }}
      />
    );
  }

  const handleBounds = ([list, index]: Position, rect: DOMRect) => {
    const newVal = { ...dropLocationsRef.current };
    if (newVal[list] === undefined) {
      newVal[list] = {};
    }
    if (newVal[list][index] !== rect.y) {
      newVal[list][index] = rect.y;
      setDropLocations(newVal);
    }
    if (list === 0 && index === -1) {
      setDropZoneTop(rect.y);
    }
    if (list === 1 && index === 0) {
      console.log("unranked top", rect.y);
    }
  };

  return (
    <RankSortContext.Provider
      value={{
        positions,
        offsets,
        ranked,
        unranked,
        items,
        onDrop: handleDrop,
        onOver: handleOver,
        onBounds: handleBounds,
        onDrag: handleDrag,
        lineHeight,
        grabId,
        minRanking,
        maxRanking,
        dropLocations,
      }}
    >
      <DndProvider
        backend={isMobile ? TouchBackend : HTML5Backend}
        options={{ enableMouseEvents: true }}
      >
        {children}
      </DndProvider>
    </RankSortContext.Provider>
  );
};
