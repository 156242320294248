import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom"
import { ElectionsContext } from "../../context/elections.provider";
import { AuthContext } from "../../context/auth.provider";
import { IBallot } from "../../domain/ballot";
import { ICandidateProfile } from "../../domain/candidate";
import { SortResult } from "../../components/rank-sort/types";

export const useElection = (election_id: string) => {
    const { getElectionById } = useContext(ElectionsContext);

    return useMemo(() => election_id ? getElectionById(election_id) : null, [election_id, getElectionById])
}



export const useBallot = () => {
    const { ballot_id } = useParams();
    const { fetch } = useContext(AuthContext);
    const [ballot, setBallot] = useState<IBallot | null>(null);

    useEffect(() => {
        if (fetch) {
            fetch({ method: 'get', url: `/ballots/${ballot_id}` }).then(setBallot);
        }
    }, [fetch, ballot_id])

    return ballot;
}

export const useCandidates = (ballot_id: string) => {
    const { fetch } = useContext(AuthContext);
    const [candidates, setCandidates] = useState<ICandidateProfile[] | null>(null);

    useEffect(() => {
        if (fetch) {
            fetch({ method: 'get', url: `/ballots/${ballot_id}/candidates` }).then(setCandidates);
        }
    }, [fetch, ballot_id])

    return candidates
}


export const sortResult = (a: SortResult, b: SortResult) => (a.index > b.index ? 1 : -1)

export const formateDate = (date: Date | string) => {
    date = new Date(date);
    return date.toDateString();
}