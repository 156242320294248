import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createContext } from "react";
import { BallotResponse, IBallot } from "../../domain/ballot";
import { useStateWithRef } from "../../hooks";
import { AuthContext } from "../../context/auth.provider";
import { useNavigate } from "react-router-dom";
import { ICandidateProfile } from "../../domain/candidate";
import { IElection } from "../../domain/election";
import {
  LocalStorageContext,
  useStoredJson,
} from "../../context/localstorage.provider";
type ResponseSet = { [ballot_id: string]: BallotResponse };

export interface SubmitResult {
  success: boolean;
  message?: string;
  code: string;
}

export interface IElectionContext {
  response: ResponseSet;
  election: IElection | null;
  setResponse: (ballot_id: string, v: BallotResponse) => void;
  showBallot: IBallot | undefined;
  ballots: Array<IBallot> | null;
  candidates: Array<ICandidateProfile> | null;

  gotoNext: () => void;
  submit: () => Promise<SubmitResult>;
}

export const ElectionContext = createContext<IElectionContext>({
  response: {},
  election: null,
  setResponse: (ballot_id: string, v: any) => null,
  showBallot: undefined,
  ballots: null,
  candidates: null,
  gotoNext: () => undefined,
  submit: () => Promise.resolve({ success: false, code: "not_initialized" }),
});

interface ElectionProviderProps extends PropsWithChildren {
  election_id: string;
  ballot_id?: string;
}
export const ElectionProvider = ({
  children,
  election_id,
  ballot_id,
}: ElectionProviderProps) => {
  const { fetch } = useContext(AuthContext);
  const [response, setResponse, refResponse] = useStoredJson<ResponseSet>(
    `response_${election_id}`,
    {}
  );
  const [election, setElection, refElection] =
    useStateWithRef<IElection | null>(null);
  const [ballots, setBallots, refBallots] =
    useStateWithRef<Array<IBallot> | null>(null);
  const [candidates, setCandidates, refCandidates] =
    useStateWithRef<Array<ICandidateProfile> | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (fetch) {
      fetch({
        url: `/elections/${election_id}`,
      }).then(setElection);
      fetch({
        url: `/elections/${election_id}/ballots`,
      }).then(setBallots);
      fetch({
        url: `/elections/${election_id}/candidates`,
      }).then(setCandidates);
    }
  }, [fetch, election_id]);

  const showBallotIndex = useMemo(
    () =>
      ballot_id && ballots
        ? ballots.findIndex((b: IBallot) => b.id === ballot_id)
        : -1,
    [ballots, ballot_id]
  );

  const showBallot = useMemo(
    () => (ballots ? ballots[showBallotIndex] : undefined),
    [ballots, showBallotIndex]
  );
  const handleSetResponse = (ballot_id: string, value: any) => {
    const r = { ...refResponse.current, [ballot_id]: value };
    setResponse(r);
  };
  const handleGotoNext = () => {
    const ballots = refBallots.current;
    console.log("handleGotoNext", ballots?.length);
    if (ballots) {
      const nextIndex = showBallotIndex + 1;
      if (nextIndex >= ballots.length) {
        for (const b of ballots) {
          if (!(b.id in response)) {
            // for some reason there is a ballot with no response
            navigate(`/election/${election_id}/ballot/${b.id}`);
          }
        }
        navigate(`/election/${election_id}/review`);
      } else {
        const next = ballots[nextIndex];
        navigate(`/election/${election_id}/ballot/${next.id}`);
      }
    }
  };
  const handleSubmit = async (): Promise<SubmitResult> => {
    try {
      if (!fetch) {
        throw Error("fetch is required for submission");
      }

      for (const ballot_id of Object.keys(response)) {
        const saved = await fetch({
          url: `/ballots/${ballot_id}/response`,
          method: "post",
          data: response[ballot_id].value,
        });
        console.log(saved);
      }
      return {
        success: true,
        code: "saved",
      };
    } catch (err) {
      return {
        success: false,
        code: String(err),
      };
    }
  };
  return (
    <ElectionContext.Provider
      value={{
        ballots,
        election,
        candidates,
        response,
        setResponse: handleSetResponse,
        showBallot,
        gotoNext: handleGotoNext,
        submit: handleSubmit,
      }}
    >
      {children}
    </ElectionContext.Provider>
  );
};
