import { Box, Button, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Candidate } from "./candidate";
import { ELECTION_TYPE, RESPONSE_TYPE } from "../../../const/vote";
import { Loading } from "../../../components/loading";
import { ICandidateProfile } from "../../../domain/candidate";
import { AuthContext } from "../../../context/auth.provider";
import { useBallot, useCandidates } from "../util";
import { IBallot } from "../../../domain/ballot";
import { PreferenceInput } from "./preference";
import { ErrorPanel } from "../../../components/error-panel";
import { ElectionContext } from "../context";

export const BallotPanel = () => {
  const { gotoNext, showBallot } = useContext(ElectionContext);

  const handleSubmit = () => {
    // TODO save to localstorage
    gotoNext();
  };

  if (!showBallot) {
    return <Loading source="BallotPanel" />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">{showBallot.label}</Typography>
        <hr />
        <Typography variant="body1">{showBallot.short_description}</Typography>
        <hr />
      </Grid>
      <Grid item xs={12} mt={4}>
        <Typography>You're enrolled in one vote:</Typography>
      </Grid>
      <Grid item xs={12}>
        <ResponseResponse ballot={showBallot} onSubmit={handleSubmit} />
        <Button variant="contained" fullWidth onClick={handleSubmit}>
          Next
        </Button>
      </Grid>
    </Grid>
  );
};
interface ResponseResponseProps {
  ballot: IBallot;
  onSubmit: (value: any) => void;
}
const ResponseResponse = ({ ballot, onSubmit }: ResponseResponseProps) => {
  switch (ballot.response_type) {
    case RESPONSE_TYPE.YES_NO:
      return <ResponseYesNo ballot={ballot} onSubmit={onSubmit} />;
    case RESPONSE_TYPE.FIRST_PAST:
      return <ResponseFirstPast ballot={ballot} onSubmit={onSubmit} />;
    default:
      return <PreferenceInput ballot={ballot} onSubmit={onSubmit} />;
  }
};

interface ResponseYesNoProps {
  ballot: IBallot;
  onSubmit: (value: any) => void;
}
const ResponseYesNo = ({ ballot }: ResponseYesNoProps) => {
  const [response, setResponse] = useState<string | null>(null);
  return (
    <Grid container>
      <Grid item xs={6}>
        <Button
          variant={response === "YES" ? "contained" : "outlined"}
          onClick={() => setResponse("YES")}
        >
          YES
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant={response === "NO" ? "contained" : "outlined"}
          onClick={() => setResponse("NO")}
        >
          NO
        </Button>
      </Grid>
    </Grid>
  );
};

interface ResponseFirstPastProps {
  ballot: IBallot;
  onSubmit: (value: any) => void;
}
const ResponseFirstPast = ({ ballot }: ResponseFirstPastProps) => {
  const [selected, setSelected] = useState<number | null>(null);
  const candidates = useCandidates(ballot.id);

  if (!candidates) {
    return <Loading source="ResponseFirstPast" />;
  }
  return (
    <>
      {candidates.map((c, i) => (
        <Candidate
          info={c}
          value={selected === i ? 1 : 0}
          response_type={RESPONSE_TYPE.RANKING}
          max_value={1}
          next_value={1}
          onChange={(v) => (v === 1 ? setSelected(i) : setSelected(null))}
        />
      ))}
    </>
  );
};
