export interface ICandidateProfile {
    "id": "a4ce60d2-6953-417c-86a2-ae73255cf7c1",
    "created_at": "2024-05-16T07:23:07.422Z",
    "updated_at": "2024-05-16T07:23:07.493Z",
    "election_id": "018f8048-6b4b-0010-0000-284b9f40505f",
    "profile_id": null,
    "title": null,
    "first_name": "Richard",
    "last_name": "Osborne",
    "email": "Raul@example.com",
    "sort_order": null,
    "preferred_name": null,
    "status": "REJECTED",
    "rejected_reason": null,
    "candidate_id": null,
    "running_id": null,
    "version": null,
    "statement": null,
    "learn_more_url": null,
    "image_sm_url": null,
    "image_lg_url": null
}

export const getCandidateLabel = (candidate: ICandidateProfile) => {
    if (candidate.preferred_name) {
        return candidate.preferred_name;
    }
    const words = [
        candidate.title,
        candidate.first_name,
        candidate.last_name
    ].filter(w => !!w);

    return words.join(' ');
}