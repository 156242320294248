import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useContext, useMemo } from "react";
import { Link, Outlet, useParams } from "react-router-dom";

import { Loading } from "../../components/loading";
import { IBallot } from "../../domain/ballot";
import { ErrorPanel } from "../../components/error-panel";
import { BallotPanel } from "./ballot";
import { ElectionContext, ElectionProvider } from "./context";
import { AuthContext } from "../../context/auth.provider";
import { formateDate } from "./util";
import { VOTER_STATUS } from "../../domain/voter";
import { ELECTION_STATUS } from "../../domain/election";
import React from "react";
import HowToVoteIcon from "@mui/icons-material/HowToVote";

export const ElectionPage = () => {
  const { election_id, ballot_id } = useParams();

  if (!election_id) {
    return <ErrorPanel text="Must have election_id" source="ElectionPage" />;
  }

  return (
    <ElectionProvider election_id={election_id} ballot_id={ballot_id}>
      <Outlet />
    </ElectionProvider>
  );
};

export const ElectionPanel = () => {
  const { voter } = useContext(AuthContext);
  const { election, ballots, gotoNext } = useContext(ElectionContext);

  if (!voter || !election || !ballots) {
    return (
      <Loading
        source="ElectionPanel"
        waitingFor={{ Election: !!election, Ballots: !!ballots }}
      />
    );
  }

  if (ballots.length === 0) {
    return <div>No Ballots</div>;
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">{election.label}</Typography>
          <Typography variant="h5">{election.status}</Typography>
          <Typography variant="h5">
            Opens: {formateDate(election.voting_open_at)}
          </Typography>
          <Typography variant="h5">
            Closes: {formateDate(election.voting_close_at)}
          </Typography>
          <hr />
        </Grid>
        {voter.status === VOTER_STATUS.SUBMITTED ? (
          <Grid item xs={12} mt={4}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Chip color="success" label="Submitted ✓" />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} mt={4}>
            <Typography>You're enrolled in one vote:</Typography>

            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                border: "1px solid #AAA",
              }}
            >
              {ballots.map((b, i) => (
                <BallotCard key={b.id} ballot={b} index={i} />
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={12} mt={4}>
          <ActionPanel
            onNext={gotoNext}
            election_id={election.id}
            electionStatus={election.status}
            voterStatus={voter.status}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

interface BallotCardProps {
  index: number;
  ballot: IBallot;
}
const BallotCard = ({ ballot, index }: BallotCardProps) => {
  return (
    <>
      {index > 0 ? <Divider variant="inset" component="li" /> : null}
      <ListItem alignItems="flex-start" sx={{ width: "100%" }}>
        <ListItemAvatar>
          <HowToVoteIcon color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary={ballot.label}
          secondary={ballot.short_description}
        />
      </ListItem>
    </>
  );
};

interface ActionPanelProps {
  election_id: string;
  electionStatus: ELECTION_STATUS;
  voterStatus: VOTER_STATUS;
  onNext: () => void;
}
const ActionPanel = ({
  election_id,
  electionStatus,
  voterStatus,
  onNext,
}: ActionPanelProps) => {
  if (voterStatus === VOTER_STATUS.SUBMITTED) {
    return (
      <Card variant="outlined" sx={{ marginTop: 10 }}>
        <Box p={3} sx={{ background: "#fbf7e9" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                You have already submitted responses to all eligible ballots in
                this election.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link to={`/election/${election_id}/dispute`}>
                <Button fullWidth>
                  Haven't voted yet?
                  <br />
                  Click here if you believe this is an error.
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  }
  switch (electionStatus) {
    case ELECTION_STATUS.CANCELLED:
      return (
        <Typography>
          This election has been cancelled. Thank you for your interest.
        </Typography>
      );

    case ELECTION_STATUS.COMPLETE:
      return (
        <Typography>
          This election has is now closed. Thank you for your interest.
        </Typography>
      );

    case ELECTION_STATUS.DRAFT:
      return (
        <Typography>
          This election is not ready for nominations or voting. Thank you for
          your interest.
        </Typography>
      );

    case ELECTION_STATUS.NOMINATIONS:
      return (
        <Typography>
          This election is currently only accepting nominations. Thank you for
          your interest.
        </Typography>
      );

    case ELECTION_STATUS.READY:
      return (
        <Typography>
          This election is not yet open for voting. Thank you for your interest.
        </Typography>
      );

    case ELECTION_STATUS.RUNNING:
      return (
        <Button onClick={onNext} variant="contained" fullWidth>
          Begin
        </Button>
      );
  }
};
