import {
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
  useContext,
} from "react";
import { IBallot } from "../../../domain/ballot";
import {
  ICandidateProfile,
  getCandidateLabel,
} from "../../../domain/candidate";
import { sortResult, useCandidates } from "../util";
import { Box, Card, Typography } from "@mui/material";
import { Loading } from "../../../components/loading";
import { RankSortContainer, RankSortElem } from "../../../components/rank-sort";
import { ElectionContext } from "../context";
import { SortResult } from "../../../components/rank-sort/types";
import { RESPONSE_TYPE } from "../../../const/vote";
import { Elem } from "../../../components/rank-sort/context";

interface PreferenceInputProps {
  ballot: IBallot;
  onSubmit: (value: any) => void;
}
export const PreferenceInput = ({ ballot }: PreferenceInputProps) => {
  const { response, setResponse } = useContext(ElectionContext);
  const candidates = useCandidates(ballot.id);
  const { shuffle_candidates, min_ranking, max_ranking } = ballot;

  const maybeShuffle = useMemo(() => {
    if (candidates) {
      return candidates
        .map((c) => ({ ...c, r: Math.random() }))
        .sort((a, b) => (a.r > b.r ? -1 : 1));
    }
    return candidates;
  }, [candidates, shuffle_candidates]);

  const elems = useMemo(() => {
    console.log("go shuffle memo");
    if (maybeShuffle) {
      return maybeShuffle.map((c, i) => (
        <RankSortElem key={c.id} id={c.id} label={getCandidateLabel(c)}>
          <CandidateDrag key={c.id} candidate={c} />
        </RankSortElem>
      ));
    }
  }, [maybeShuffle]);

  const handleResponse = (value: Array<SortResult<string>>, valid: boolean) => {
    setResponse(ballot.id, {
      ballot_id: ballot.id,
      type: RESPONSE_TYPE.RANKING,
      value,
      valid,
    });
  };

  const initialValue = useMemo(() => {
    const initial = response[ballot.id];

    if (initial) {
      return initial.value
        .sort(sortResult)
        .map((c): Elem => ({ id: c.id, label: c.label }));
    }
  }, [response]);

  if (!elems) {
    return <Loading source="PreferenceInput" waitingFor={{ Elems: !!elems }} />;
  }

  return (
    <Box>
      <Box>
        {min_ranking ? (
          <Typography>
            You must choose at least{" "}
            <b>
              <u>{min_ranking}</u>
            </b>{" "}
            candidates.
          </Typography>
        ) : null}
        {max_ranking ? (
          <Typography>
            You can choose at most{" "}
            <b>
              <u>{max_ranking}</u>
            </b>{" "}
            candidates.
          </Typography>
        ) : null}
      </Box>
      <RankSortContainer
        initial={initialValue}
        minRanking={min_ranking}
        maxRanking={max_ranking}
        onChange={handleResponse}
      >
        {elems}
      </RankSortContainer>
    </Box>
  );
};

interface CandidateDragProps {
  candidate: ICandidateProfile;
}

const CandidateDrag = ({ candidate }: CandidateDragProps) => {
  const label = useMemo(() => getCandidateLabel(candidate), [candidate]);
  return <Typography>{label}</Typography>;
};
