import React, {
  createContext,
  useState,
  PropsWithChildren,
  useContext,
} from "react";
import { useStateWithRef } from "../hooks";

export interface ILocalStorageContext {
  getString: (key: string, fallback?: string) => string | typeof fallback;
  setString: (key: string, value: string) => void;
  getJson: <T>(key: string, fallback: T) => T | typeof fallback;
  setJson: <T>(key: string, value: T) => void;
  delValue: (key: string) => void;
}

export type IStore = { [key: string]: any };

export const LocalStorageContext = createContext<ILocalStorageContext>({
  getString: (key: string, fallback?: string) => fallback,
  setString: (key: string, value: string) => null,
  getJson: <T,>(key: string, fallback: T) => fallback,
  setJson: <T,>(key: string, value: T) => null,
  delValue: (key: string) => null,
});
export const LocalStorageProvider: React.FC<PropsWithChildren> = (props) => {
  const [store, setStore] = useStateWithRef<IStore>({ ...localStorage });

  const getString = (key: string, fallback?: string): string | undefined => {
    if (key in store) {
      return store[key];
    }
    const v = localStorage.getItem(key);
    if (v === null && fallback) {
      setString(key, fallback);
    }
    return localStorage.getItem(key) || undefined;
  };

  const setString = (key: string, value: string) => {
    localStorage.setItem(key, value);
    setStore({ ...localStorage });
  };

  const delValue = (key: string) => {
    localStorage.removeItem(key);
    setStore((v) => {
      delete v[key];
      return v;
    });
  };

  const getJson = <TValue,>(
    key: string,
    fallback?: any
  ): TValue | typeof fallback => {
    const v = getString(key);
    if (!v) {
      if (!fallback) {
        setJson(key, fallback);
      }
      return fallback;
    }
    return JSON.parse(v);
  };

  const setJson = <TValue,>(key: string, value: TValue) => {
    setString(key, JSON.stringify(value));
  };

  return (
    <LocalStorageContext.Provider
      value={{ getString, setString, delValue, getJson, setJson }}
    >
      {props.children}
    </LocalStorageContext.Provider>
  );
};

export const useStoredJson = <T,>(
  key: string,
  initial: T
): [T | typeof initial, (v: T) => void, React.MutableRefObject<T>] => {
  const { getJson, setJson } = useContext(LocalStorageContext);
  const [state, setState, ref] = useStateWithRef(getJson(key, initial));
  const handleSetValue = (v: T) => {
    setState(v);
    setJson(key, v);
  };
  return [state, handleSetValue, ref];
};
