import { Box, Grid, Input, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { RESPONSE_TYPE } from "../../../const/vote";
import { CheckBox, SquareOutlined } from "@mui/icons-material";
import { ICandidateProfile } from "../../../domain/candidate";
export interface CandidateProps {
  info: ICandidateProfile;
  value: number | undefined;
  response_type: RESPONSE_TYPE;
  max_value: number;
  next_value: number;
  onChange: (v: number | undefined) => void;
}

export const Candidate = ({
  info,
  value,
  max_value,
  response_type,
  next_value,
  onChange,
}: CandidateProps) => {
  const { title, first_name, last_name, preferred_name } = info;
  const label = useMemo(() => {
    if (preferred_name) return preferred_name;
    if (title) return [title, first_name, last_name].join(" ");
    return [first_name, last_name].join(" ");
  }, [title, first_name, last_name, preferred_name]);

  const handleClick = () => {
    if (value == undefined) {
      onChange(next_value);
    } else {
      const newVal = value + 1;
      if (newVal > max_value) {
        onChange(undefined);
      } else {
        onChange(newVal);
      }
    }
  };
  return (
    <Box onClick={handleClick}>
      <Grid container>
        <Grid item xs={2} sm={1}>
          {response_type == RESPONSE_TYPE.YES_NO ? (
            value === 1 ? (
              <CheckBox />
            ) : (
              <SquareOutlined />
            )
          ) : (
            <Input value={value} />
          )}
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography>{label}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
