import { Grid, Typography } from "@mui/material";
import React from "react";

export const LandingPage = () => {
  return (
    <Grid container>
      <Grid item>
        <Typography variant="h1">Hello!</Typography>
        <Typography variant="h2">Landing Page</Typography>
      </Grid>
    </Grid>
  );
};
