export enum RESPONSE_TYPE {
    YES_NO = "YES_NO",
    RANKING = "RANKING",
    PREFERENCE = "PREFERENCE",
    FIRST_PAST = "FIRST_PAST"
}

export enum ELECTION_TYPE {
    YES_NO = "YES_NO",
    RANKING = "RANKING",
    PREFERENCE = "PREFERENCE",
    FIRST_PAST = "FIRST_PAST"
}