import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { AUTH_STATUS, AuthContext } from "./auth.provider";
import { IBallot } from "../domain/ballot";
import { useStateWithRef } from "../hooks";

interface BallotsFetch {
  election_id: string;
  fetch_at: number;
  ballots: Array<IBallot>;
}

export interface IBallotsContext {
  fetchBallots: null | ((election_id: string) => Promise<Array<IBallot>>);
  getBallotById: (id: string) => IBallot | null;
}

export const BallotsContext = createContext<IBallotsContext>({
  fetchBallots: null,
  getBallotById: () => null,
});

export const BallotsProvider: React.FC<PropsWithChildren> = (props) => {
  const [ballots, setBallots, refBallots] = useStateWithRef<{
    [election_id: string]: BallotsFetch;
  }>({});

  const { fetch, status } = useContext(AuthContext);
  const fetchBallots = useMemo(() => {
    if (!fetch) {
      return null;
    }
    return async (election_id: string) => {
      if (status === AUTH_STATUS.AUTHENTICATED) {
        const cache = refBallots.current[election_id];
        const expired = Date.now() - 60 * 1000;
        if (cache && cache.fetch_at > expired) {
          return cache.ballots;
        }
        const newBallots = await fetch({
          method: "get",
          url: `/elections/${election_id}/ballots`,
        });
        newBallots.sort((a: IBallot, b: IBallot) => {
          if (a.display_order === null || a.display_order === undefined) {
            if (b.display_order === null || b.display_order === undefined) {
              return 0;
            }
            return -1;
          }
          if (b.display_order === null || b.display_order === undefined) {
            return 1;
          }
          if (a.display_order > b.display_order) {
            return 1;
          }
          if (a.display_order < b.display_order) {
            return -1;
          }
          return 0;
        });
        setBallots({
          ...refBallots.current,
          [election_id]: {
            election_id,
            fetch_at: Date.now(),
            ballots: newBallots,
          },
        });
        return newBallots;
      }
    };
  }, [fetch, status]);

  const getBallotById = (ballot_id: string) => {
    if (!ballots) {
      return null;
    }
    for (const election_id of Object.keys(ballots)) {
      const ballot = ballots[election_id].ballots.find(
        (b) => b.id === ballot_id
      );
      if (ballot) {
        return ballot;
      }
    }
    return null;
  };

  return (
    <BallotsContext.Provider value={{ fetchBallots, getBallotById }}>
      {props.children}
    </BallotsContext.Provider>
  );
};

export const useBallots = (election_id: string) => {
  const [ballots, setBallots] = useState<Array<IBallot> | null>();
  const { fetchBallots } = useContext(BallotsContext);
  useEffect(() => {
    if (fetchBallots) {
      fetchBallots(election_id).then(setBallots);
    }
  }, [fetchBallots, election_id]);

  return ballots;
};
