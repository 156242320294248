import { Grid, Typography } from "@mui/material";
import { Loading } from "../components/loading";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/auth.provider";
import { useNavigate, useLocation } from "react-router-dom";
import { IEmailToken } from "../domain/email-token";

export const EmailTokenPage = () => {
  const { hash } = useLocation();

  const { authByEmailToken } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(hash, hash.length);
  useEffect(() => {
    console.log("RUN T");
    if (hash) {
      authByEmailToken(hash.replace("#", "")).then(
        (email_token: IEmailToken) => {
          if (email_token.election_id) {
            navigate("/election/" + email_token.election_id);
          } else {
            navigate("/");
          }
        }
      );
    }
  }, [hash]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2">Authenticating...</Typography>
      </Grid>
    </Grid>
  );
};
