import {
  useState,
  useEffect,
  useMemo,
  PropsWithChildren,
  Children,
  useCallback,
  useContext,
} from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Box, Card, Chip } from "@mui/material";
import { Loading } from "../loading";
import { useStateWithRef } from "../../hooks";
import { TouchBackend } from "react-dnd-touch-backend";
import { isMobile } from "react-device-detect";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { IdType, Position, SortResult } from "./types";
import { Elem, RankSortContext, RankSortProvider } from "./context";
export * from "./rank-sort-elem";
const LINE_HEIGHT = 50;
const LIST_GAP = 30;

function create_array<T = any>(size: number, value?: T): Array<T> {
  const v = new Array(size);
  for (let i = 0; i < size; i++) {
    v[i] = value || i;
  }
  return v;
}
interface DragElem {
  id: IdType;
  label?: string;
  elem: JSX.Element;
}

const getId = (element: any, fallback: IdType = ""): IdType =>
  "props" in element ? element.props.id : fallback;

const getLabel = (element: any, fallback?: string): string | undefined =>
  "props" in element ? element.props.label : fallback;

interface RankSortContainerProps extends PropsWithChildren {
  initial?: Array<Elem>;
  maxRanking?: number;
  minRanking?: number;
  onChange?: (val: Array<SortResult>, valid: boolean) => void;
}
export const RankSortContainer = ({
  children,
  initial,
  maxRanking,
  minRanking,
  onChange,
}: RankSortContainerProps) => {
  const elements: Array<DragElem> = useMemo(() => {
    return Children.toArray(children as any).map(
      (c, i): DragElem => ({
        id: getId(c, i.toString()),
        label: getLabel(c),
        elem: c as any,
      })
    );
  }, [children]);

  const items: Array<Elem> = useMemo(() => {
    return elements.map((e) => ({ id: e.id, label: e.label }));
  }, [elements]);

  return (
    <DndProvider
      backend={isMobile ? TouchBackend : HTML5Backend}
      options={{ enableMouseEvents: true }}
    >
      <RankSortProvider
        initial={initial}
        minRanking={minRanking}
        maxRanking={maxRanking}
        items={items}
        onChange={onChange}
      >
        <Box sx={{ position: "relative", marginBottom: LINE_HEIGHT + "px" }}>
          <RankedArea />
          <UnrankedArea />
          {elements.map((e, i) => (
            <OptionDrag key={e.id} id={e.id}>
              {e.elem}
            </OptionDrag>
          ))}
        </Box>
      </RankSortProvider>
    </DndProvider>
  );
};

const RankedArea = () => {
  const { items, ranked, minRanking, maxRanking, onBounds } =
    useContext(RankSortContext);
  const dropRows: Array<DROPZONE_TYPE> = useMemo(() => {
    const min = minRanking || 1;
    const max = maxRanking ? maxRanking : items.length;
    const total = ranked.length < max ? ranked.length + 1 : ranked.length;
    let optional = total - min;

    const arr = new Array(min).fill(DROPZONE_TYPE.REQUIED);
    if (optional > 0) {
      arr.push(...new Array(optional).fill(DROPZONE_TYPE.OPTIONAL));
    }
    return arr;
  }, [ranked]);
  const handleRef = (el: HTMLElement) => {
    if (el) onBounds([0, -1], el.getBoundingClientRect());
  };
  return (
    <Box ref={handleRef}>
      {dropRows.map((t, i) => (
        <DropZone key={i} position={[0, i]} type={t} />
      ))}
    </Box>
  );
};

const UnrankedArea = () => {
  const { onBounds, unranked } = useContext(RankSortContext);
  const dropRows = useMemo(() => {
    return unranked.length;
  }, [unranked]);
  const handleRef = (el: HTMLElement | null) => {
    if (el) onBounds([1, -1], el.getBoundingClientRect());
  };
  return (
    <Box
      ref={handleRef}
      sx={{
        marginTop: LIST_GAP + "px",
        position: "relative",
        border: "#CCC solid 1px",
      }}
    >
      {new Array(dropRows).fill(0).map((c, i) => (
        <DropZone key={i} position={[1, i]} type={DROPZONE_TYPE.UNRANKED} />
      ))}
    </Box>
  );
};

enum DROPZONE_TYPE {
  REQUIED,
  OPTIONAL,
  UNRANKED,
}
interface DropZoneProps extends PropsWithChildren {
  position: Position;
  type: DROPZONE_TYPE;
}
const DropZone = ({ position, type }: DropZoneProps) => {
  const { onDrop, onOver, onBounds } = useContext(RankSortContext);
  const handleDrop = ({ id }: { id: IdType }) => {
    onDrop(position, id);
  };
  const [_, index] = position;
  const [{ isOver, canDrop, item }, drop] = useDrop(
    () => ({
      accept: "candidate",
      drop: handleDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
        item: monitor.getItem(),
      }),
    }),
    position
  );
  useEffect(() => onOver(position, isOver), [isOver]);
  const handleRef = (el: HTMLDivElement | null) => {
    if (el) onBounds(position, el.getBoundingClientRect());
    drop(el);
  };
  const chip = useMemo(() => {
    switch (type) {
      case DROPZONE_TYPE.REQUIED:
        return <Chip label={index + 1} color="error" />;
      case DROPZONE_TYPE.OPTIONAL:
        return <Chip label={index + 1} color="info" />;
      case DROPZONE_TYPE.UNRANKED:
        return <Chip label="-" color="default" />;
    }
  }, [type, index]);
  const border = useMemo(() => {
    switch (type) {
      case DROPZONE_TYPE.REQUIED:
      case DROPZONE_TYPE.OPTIONAL:
        return "#CCC solid 1px";
      case DROPZONE_TYPE.UNRANKED:
        return undefined;
    }
  }, [type]);
  const hint = useMemo(() => {
    switch (type) {
      case DROPZONE_TYPE.REQUIED:
        return "(required)";
      case DROPZONE_TYPE.OPTIONAL:
        return "(optional)";
      case DROPZONE_TYPE.UNRANKED:
        return undefined;
    }
  }, [type]);
  return (
    <Card
      ref={handleRef}
      sx={{
        border: border,
        boxSizing: "border-box",
        position: "relative",
        display: "flex",
        alignItems: "center",
        background: isOver ? "#f1f3be" : undefined,
      }}
    >
      <Box
        sx={{
          transition: "ease height 0.01s",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          boxSizing: "border-box",
          padding: 1,
          paddingLeft: 1.5,
        }}
      >
        {chip}
      </Box>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          top: -2,
          left: 5,
          paddingLeft: 1,
        }}
      >
        {hint}
      </Box>
    </Card>
  );
};

interface OptionDragProps extends PropsWithChildren {
  id: IdType;
}

const OptionDrag = ({ id, children }: OptionDragProps) => {
  const { offsets, positions, lineHeight, onDrag, grabId } =
    useContext(RankSortContext);
  const [cardHeight, setCardHeight] = useState<number>(0);
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "candidate",
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: { id },
    }),
    [id]
  );
  const otherDragging = grabId !== null && grabId !== id;
  const position = positions[id];
  const offset = offsets[id] + (lineHeight - cardHeight) / 2;
  const [smooth, setSmooth] = useState<boolean>(true);
  useEffect(() => {
    onDrag(position, id, isDragging);
    if (isDragging) {
      setSmooth(false);
    } else {
      setTimeout(() => setSmooth(true), 10);
    }
  }, [id, isDragging]);
  if (isDragging) {
    return null;
  }

  const handleRef = (el: HTMLDivElement | null) => {
    drag(el);
    if (el) setCardHeight(el.getBoundingClientRect().height);
  };

  return (
    <Card
      ref={handleRef}
      sx={{
        position: "absolute",
        top: offset,
        left: 60,
        display: "flex",
        transition: smooth ? "ease top 0.5s" : undefined,
        pointerEvents: otherDragging ? "none" : undefined,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 0.7,
        paddingRight: 0.7,

        background: "#f5f5f5",
        border: "#ddd solid 1px",
        cursor: "grab",
      }}
    >
      <DragIndicatorIcon /> {isDragging ? null : children}
    </Card>
  );
};

/*
          {candidates &&
            candidates.map((c, i) => (
              <Candidate
                info={c}
                value={votes[i]}
                response_type={RESPONSE_TYPE.RANK}
                max_value={max_preference}
                next_value={nextVal}
                onChange={(v) =>
                  setVotes(() => {
                    const current = votes.slice(0);
                    current[i] = v;
                    console.log(i, v, current);
                    return current;
                  })
                }
              />
            ))}
            */
