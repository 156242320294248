import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

interface DragChildProps extends PropsWithChildren {
  id: string;
  label?: string;
}
export const RankSortElem = ({ children }: DragChildProps) => {
  return <Box>{children}</Box>;
};
