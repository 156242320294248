import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { InitializePage } from "./pages/initialize";
import { EmailTokenPage } from "./pages/email-token.page";
import { useContext } from "react";
import { AUTH_STATUS, AuthContext } from "./context/auth.provider";
import { Loading } from "./components/loading";
import { ElectionPage, ElectionPanel } from "./pages/election";
import { LandingPage } from "./pages/landing-page";
import { Redirect } from "./components/redirect";
import { BallotPanel } from "./pages/election/ballot";
import { ReviewPage } from "./pages/election/review";
import { DisputePage } from "./pages/election/dispute";
import { CreateTicket } from "./pages/election/dispute/create-ticket";

const NotFound = () => {
  return (
    <>
      <Redirect to="/" />
      <Typography>Not found</Typography>
    </>
  );
};

const guestRoutes = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/email-token",
    element: <EmailTokenPage />,
  },
]);

const authenticatedRoutes = createBrowserRouter([
  {
    path: "/",
    element: <InitializePage />,
  },
  {
    path: "/email-token",
    element: <EmailTokenPage />,
  },
  {
    path: "/election/:election_id",
    element: <ElectionPage />,
    children: [
      {
        index: true,
        element: <ElectionPanel />,
      },
      {
        path: "ballot/:ballot_id",
        element: <BallotPanel />,
      },
      {
        path: "review",
        element: <ReviewPage />,
      },
      {
        path: "dispute",
        element: <DisputePage />,
      },
      {
        path: "create-ticket",
        element: <CreateTicket />,
      },
    ],
  },
  {
    path: "create-ticket",
    element: <CreateTicket />,
  },
]);

function App() {
  return (
    <>
      <AppBar position="static" sx={{ background: "000" }}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Nominal Voting Inc.
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Page />
        </Box>
      </Container>
    </>
  );
}

function Page() {
  const { status } = useContext(AuthContext);

  switch (status) {
    case AUTH_STATUS.VERIFYING:
      return <Loading source="PageBase" />;
    case AUTH_STATUS.UNAUTHENTICATED:
      return <RouterProvider router={guestRoutes} />;
    case AUTH_STATUS.AUTHENTICATED:
      return <RouterProvider router={authenticatedRoutes} />;
  }
}

export default App;
