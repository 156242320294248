import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../../context/auth.provider";
import { Box, Card, Grid, MenuItem, Select, Typography } from "@mui/material";
import { IUnsavedTicket, TICKET_REASON } from "../../../domain/domain";
import {
  FormSubmitHandler,
  SelectInput,
  SimpleForm,
  TextInput,
} from "../../../components/simple-form";
import { useStateWithRef } from "../../../hooks";
import { useSearchParams } from "react-router-dom";

export const CreateTicket = () => {
  const [query] = useSearchParams();
  const initialReason = query.get("reason") || "";

  const hint = useMemo(() => {
    switch (initialReason) {
      case TICKET_REASON.WRONG_PERSON:
        return "If you believe someone else voted using your invite please add their details below so we can follow up with that person.";
      case TICKET_REASON.DID_NOT_VOTE:
        return "If you did not vote and you're sure you never sent your invite link to anyone then we will need to investigate the incident. We will remove your vote from the box, but we cannot guarantee ou will be able to submit another vote.";
    }
  }, [initialReason]);

  const initialSummary = useMemo(() => {
    switch (initialReason) {
      case TICKET_REASON.WRONG_PERSON:
        return "Wrong person voted with my invite.";
    }
    return "";
  }, [initialReason]);

  const { fetch } = useContext(AuthContext);
  const [form, setForm] = useStateWithRef<IUnsavedTicket>({
    reason: (initialReason as TICKET_REASON) || TICKET_REASON,
    voter_summary: initialSummary,
    voter_description: "",
  });

  const handleSubmit: FormSubmitHandler = async (v: any) => {
    if (fetch) {
      try {
        const response = fetch({
          url: `/tickets`,
          method: "post",
          data: v,
        });
        return { success: true, message: "Sent!" };
      } catch (err) {
        return { success: false, message: String(err) };
      }
    } else {
      return {
        success: false,
        message: "fetch not initialized",
      };
    }
  };

  return (
    <Card raised>
      <Box p={3}>
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Typography variant="h4">Create Ticket</Typography>
            {hint ? <Typography>{hint}</Typography> : null}
          </Grid>
          <Grid item xs={12} mb={3}>
            <SimpleForm initialValue={form} onSubmit={handleSubmit}>
              <SelectInput
                field="reason"
                options={TICKET_REASON}
                hide={() => !!initialReason}
              />
              <TextInput field="voter_summary" label="Summary" />
              <TextInput
                field="voter_description"
                label="Description"
                multiline={4}
              />
            </SimpleForm>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
