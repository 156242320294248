import { Box, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTimeout } from "../hooks";
import "./loading.scss";
interface Loading {
  source: string;
  waitingFor?: { [label: string]: boolean };
}
export const Loading = ({ source, waitingFor }: Loading) => {
  const showInfo = useTimeout(2000);
  const waitElem = useMemo(() => {
    if (!waitingFor) return null;
    return (
      <Box>
        {Object.keys(waitingFor).map((w, i) => (
          <Typography key={i}>
            {w}: {waitingFor[w] ? "done" : "..."}
          </Typography>
        ))}
      </Box>
    );
  }, [waitingFor]);
  const done = useMemo(() => {
    if (!waitingFor) return false;
    for (const key of Object.keys(waitingFor)) {
      if (!waitingFor[key]) {
        return false;
      }
    }
    return true;
  }, [waitingFor]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div
            className={done ? "circle-loader load-complete" : "circle-loader"}
          >
            <div
              className="checkmark draw"
              style={{ display: done ? undefined : "none" }}
            ></div>
          </div>
        </Box>
      </Grid>
      {showInfo && !done ? (
        <Grid item xs={12}>
          <Box>{source}</Box>
          {waitElem}
        </Grid>
      ) : null}
    </Grid>
  );
};
