import React, { useContext } from "react";
import { ElectionsContext } from "../context/elections.provider";
import { Loading } from "../components/loading";
import { AuthContext } from "../context/auth.provider";
import { Redirect } from "../components/redirect";

export const InitializePage = () => {
  const { elections } = useContext(ElectionsContext);
  const { voter } = useContext(AuthContext);

  if (!voter || !elections) {
    return (
      <Loading
        source="InitializePage"
        waitingFor={{ Voter: !!voter, Election: !!elections }}
      />
    );
  }

  if (elections && elections.length === 0) {
    return <div>No elections</div>;
  }

  const election = elections[0];

  return <Redirect to={`/election/${election.id}`} />;
};
